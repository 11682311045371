import { UnitsOfMeasure } from 'types/units';
import { roundNumber } from './functions';

export const convertKgToLb = (kg: number): number => {
  const lb = kg * 2.20462;
  return roundNumber(lb, 4);
};

export const convertLbToKg = (lb: number): number => {
  const kg = lb / 2.20462;
  return roundNumber(kg, 4);
};

export const decideWeightDisplayValue = (
  weight: number,
  units: UnitsOfMeasure,
) => {
  const weightDisplay =
    units === UnitsOfMeasure.Imperial ? convertKgToLb(weight) : weight;

  return `${roundNumber(weightDisplay, 0)} ${
    units === UnitsOfMeasure.Imperial ? ' lbs' : ' kg'
  }`;
};

export const decideWeightDisplayValue2 = (
  weight: number,
  units: UnitsOfMeasure,
) => {
  const weightDisplay =
    units === UnitsOfMeasure.Imperial ? convertKgToLb(weight) : weight;

  return `${roundNumber(weightDisplay, 0)} ${
    units === UnitsOfMeasure.Imperial ? ' lbs' : ' kgs'
  }`;
};

export const decideWeightDisplayValueForCopy = (
  weight: number,
  units: UnitsOfMeasure,
) => {
  const weightDisplay =
    units === UnitsOfMeasure.Imperial ? convertKgToLb(weight) : weight;

  return `${roundNumber(weightDisplay, 0)} ${
    units === UnitsOfMeasure.Imperial ? ' pounds' : ' kgs'
  }`;
};

export const decideWeightDisplayValueWithoutUnits = (
  weight: number,
  units: UnitsOfMeasure,
) => {
  const weightDisplay =
    units === UnitsOfMeasure.Imperial ? convertKgToLb(weight) : weight;

  return roundNumber(weightDisplay, 0);
};

export const convertCmToFtIn = (cm?: number) => {
  if (!cm || cm <= 0 || isNaN(cm)) return { feet: 0, inches: 0 };

  const totalInches = cm * 0.393701;
  let feet = Math.floor(totalInches / 12);
  let inches = Math.round(totalInches % 12);

  // Handle rounding case where inches become 12
  if (inches === 12) {
    feet += 1;
    inches = 0;
  }

  return { feet, inches };
};

export const convertFtInToCm = (feet?: number, inches?: number) => {
  if ((!feet && feet !== 0) || feet < 0 || isNaN(feet)) {
    feet = 0;
  }
  if ((!inches && inches !== 0) || inches < 0 || isNaN(inches)) {
    inches = 0;
  }

  const totalInches = feet * 12 + inches;

  const cm = totalInches * 2.54;

  return Math.round(cm);
};

export const decideHeightDisplayValue = (
  value?: number,
  unitSystem?: UnitsOfMeasure,
  showUnitLabel = false,
): string => {
  if (!value) return '';
  if (unitSystem === UnitsOfMeasure.Metric)
    return `${value}${showUnitLabel ? ' cm' : ''}`;
  if (unitSystem === UnitsOfMeasure.Imperial) {
    const { feet, inches } = convertCmToFtIn(value);
    return `${feet}${showUnitLabel ? 'ft' : ''} ${inches}${
      showUnitLabel ? 'in' : ''
    }`;
  }

  return '';
};

export const calculateBMI = ({
  height,
  weight,
}: {
  height: number;
  weight: number;
}) => {
  const heightInMeters = height / 100;
  const bmi = Number((weight / (heightInMeters * heightInMeters)).toFixed(2));

  return isNaN(bmi) ? 0 : bmi;
};

export const calculateTargetLoss = (
  currentWeight: number,
  targetWeight: number,
) => {
  const weightLoss = currentWeight - targetWeight;
  const target = (weightLoss * 100) / currentWeight;

  return Number(target.toFixed());
};
